//Config
import { ROUTES } from "Config/config";
//Onboarding
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "Pages/ResetPassword/ResetPassword";
import SignIn from "Pages/SignIn/SignIn";
import SignUp from "Pages/SignUp/SignUp";
//Private
import Home from "Pages/Home/Home";
import WatchVideoRoomPage from "Pages/WatchVideoRoomPage/WatchVideoRoomPage";
import Organization from "Pages/Organization/Organization";
import EditProfile from "Pages/EditProfile/EditProfile";
import LadingCode from "Pages/LadingCode/LadingCode";
import QuizPage from "Pages/QuizPage/QuizPage";
import RegisterEmailPage from "Pages/RegisterEmailPage/RegisterEmailPage";
import AppreciationPage from "Pages/AppreciationPage/AppreciationPage";
import ResultPage from "Pages/Result/ResultPage";
import AssigmentPage from "Pages/AssigmentPage/AssigmentPage";

const getComponent = (path) => {
  switch (path) {
    //Onboarding Routes
    case ROUTES.SIGNIN:
      return SignIn;
    /*case ROUTES.SIGNUP:
      return SignUp;*/
    case `${ROUTES.RESET_PASSWORD}/:email/:code`:
      return ResetPassword;
    case ROUTES.FORGOT_PASSWORD:
      return ForgotPassword;
    case ROUTES.ORGANIZATION:
      return Organization;
    case ROUTES.LADING_PAGE:
      return LadingCode;
    case ROUTES.QUIZ_PAGE:
      return QuizPage;
    case ROUTES.APPRECIATION:
      return AppreciationPage;
    // //Private Routes
    case ROUTES.HOME:
      return Home;
    case `${ROUTES.ROOM}/:roomId/:videoId`:
      return WatchVideoRoomPage;
    case `${ROUTES.VIDEO}/:videoId`:
      return WatchVideoRoomPage;
    case ROUTES.EDIT_PROFILE:
      return EditProfile;
    case ROUTES.ADD_EMAIL:
      return RegisterEmailPage;
    case ROUTES.RESULT:
      return ResultPage;
    case `${ROUTES.ASSIGMENTS}/:resourceId`:
      return AssigmentPage;
    default:
      return null;
  }
};

const injectComponents = (modules = []) => {
  return modules.map((module) => ({
    ...module,
    component: getComponent(module.path),
  }));
};

export default injectComponents;
