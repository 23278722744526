/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
//zoom
import { ZoomMtg } from "@zoomus/websdk";
//css
import "./ZoomRoom.css";

const crypto = require("crypto"); // crypto comes with Node.js

export const currentUserLeaveZoomMeet = () => {
  ZoomMtg.leaveMeeting({
    success: function (response) {
      
    },
    error: function (err) {},
  });
};
// };

export const getAllParticipan = (getLastUserMeet) => {
  ZoomMtg.getAttendeeslist({
    success: function (response) {
      const { result } = response;
      const getcurrentAttendees = result?.attendeesList;

      getLastUserMeet(getcurrentAttendees);
    },
  });
};

export const ZoomRoom = ({ settings, currentUser }) => {
  const { apiKey, apiSecret, meetingNumber, passWord   } = settings;

  const generateSignature = (apiKey, apiSecret, meetingNumber, role) => {
    // Prevent time sync issue between client signature generation and zoom
    const timestamp = new Date().getTime() - 30000;
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
      "base64"
    );
    const hash = crypto
      .createHmac("sha256", apiSecret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
    ).toString("base64");

    return signature;
  };

  useEffect(() => {
    const zoomMeetingSDK = document.getElementById("zmmtg-root");
    zoomMeetingSDK.style.display = "block";

    const signature = generateSignature(apiKey, apiSecret, meetingNumber, 0);
    //node_modules/@zoomus/websdk/dist/lib
    //loading zoom global library my version is 2.0.1"
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.1.1/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.lockMeeting({
      lockMeeting: true,
    });
    ZoomMtg.showInviteFunction({
      show: false,
    });

    initiateMeeting(signature);

    return () => {
      // getAllParticipan(getLastUserMeet);
      currentUserLeaveZoomMeet();

      const zoomMeetingSDK = document.getElementById("zmmtg-root");
      zoomMeetingSDK.style.display = "none";
    };
  }, []);

  const initiateMeeting = (signature) => {
    ZoomMtg.init({
      leaveUrl: `https://brave-khorana-5f7098.netlify.app/`,
      disableInvite: true,
      // disableRecord: true,
      // screenShare: false,
      isSupportAV: true,
      meetingInfo: ["topic", "host", "participant"],
      success: (success) => {
        const currentUserName = `${currentUser.name} ${currentUser.firstLastName} ${currentUser.secondLastName}`;

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: currentUserName,
          apiKey: apiKey,
          userEmail: currentUser.email,
          passWord: passWord,
          success: (success) => {
            
          },
          error: (error) => {
            console.log("error", error);
          },
        });
      },
      error: (error) => {
        console.log("error", error);
      },
    });
  };
  return <div className="w-full h-full text-white p-12"></div>;
};
