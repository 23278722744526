import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "Components";
import { API_URL, ROUTES } from "Config/config";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";

const data = {
  599: {
    name: "¿Qué es el Bautismo?",
    questions: [
      "1) ¿Qué significa la palabra bautizo?",
      "2) Mencione requisitos que necesito para ser bautizado",
      "3) ¿Qué simboliza el bautismo en aguas para nuestra vida?",
    ],
  },
  603: {
    name: "El arrebatamiento y la salvacion",
    questions: [
      "1)	¿Cuál es la diferencia entre el arrebatamiento y la segunda venida de Cristo?",
      "2)	¿Qué tiempo transcurrirá entre el arrebatamiento y la segunda venida de Cristo?",
      "3)	¿Qué recibiremos con el arrebatamiento?"
    ],
  },
  604: {
    name: "Mayordomía",
    questions: [
      "1)	¿A qué se refiere la Biblia cuando habla de Mayordomía?",
      "2)	¿Cuáles son las bendiciones del diezmo?",
      "3)	¿Qué es el contentamiento?"
    ],
  },
};

const Evaluation = () => {
  const [answersSent, setAnswersSent] = useState(false);
  const [error, setError] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser);
  const organizationId = useSelector((state) => state.organization.id);

  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    const newAnswers = [];
    if (data[id] && data[id].questions) {
      data[id].questions.forEach((q) => {
        newAnswers.push({
          question: q,
          answer: "",
        });
      });
    }
    setAnswers(newAnswers);
  }, [id]);

  const setAnswer = (evt, index) => {
    setError(false);
    const newAnswers = answers;
    newAnswers[index].answer = evt.target.value;
    setAnswers(newAnswers);
  };

  const submit = async () => {
    setLoading(true);
    const noAnswered = answers.find((a) => !a.answer);
    if (noAnswered) {
      setError("Debe completar todas las preguntas");
      return;
    }
    const answersFetch = await fetch(
      `${API_URL}organizations/${organizationId}/resources/${id}/evaluation`,
      {
        method: "POST",
        headers: {
          Authorization: currentUser.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answers
        })
      }
    );
    //check if status code is 201
    if (answersFetch.status === 201) {
      setAnswersSent(true);
      setLoading(false);
      setTimeout(() => {
        history.push(ROUTES.HOME);
      }, 4000);
    }
    else {
      setLoading(false);
      setError("Ocurrió un error al guardar tus respuesta. Volvé a intentarlo.")
    }
  };

  return (
    <div className="w-screen min-h-screen md:pt-28 md:pb-14 px-16">
      {loading && <Spinner />}
      <Link
        to={ROUTES.HOME}
      >
        <div style={{ color: "#6CE0F1" }} className="mb-8 text-3xl cursor-pointer">
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      </Link>
      <p className="text-white text-2xl font-bold">
        Evaluación: {data[id].name}
      </p>
      <div>
        {data[id].questions.map((q, index) => {
          return (
            <div key={`question_${index}`} className="w-full mt-2">
              <label className="text-white">{q}</label>
              <textarea
                onChange={(evt) => {
                  setAnswer(evt, index);
                }}
                className="w-full px-2 pt-2"
                rows={3}
              ></textarea>
            </div>
          );
        })}
      </div>
      {answersSent ? (
        <div
          style={{ backgroundColor: "#6CE0F1" }}
          className="w-full mt-4 py-2 px-5 text-center"
        >
          ¡Gracias. Hemos recibido tus respuestas! Vas a ser redirigido a la
          pantalla principal.
        </div>
      ) : (
        <>
          {error && (
            <div
              style={{ backgroundColor: "#c00" }}
              className="text-white w-full mt-4 py-2 px-5 text-center"
            >
              {error}
            </div>
          )}
          <button
            onClick={submit}
            className="cursor-pointer w-full bg-primary-button py-3 rounded-sm mt-4"
          >
            Enviar respuestas
          </button>
        </>
      )}
    </div>
  );
};

export default Evaluation;
