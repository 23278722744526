import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
/* GLOBAL */
  html {
    scroll-behavior: smooth;
  }

  /* BODY */
  body {
    font-family: ${(props) => props.theme.fonts.family};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: #141414;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  /* ANTD */

  /* MODAL */
  .ant-modal-body {
    background-color: #141414;
  }
  .ant-modal-content {
    background-color: #141414;
  }

  .ant-modal-close-x {
    color: #fff;
  }

  /* SELECT */
  .ant-select-selector {
    color: #fff !important;
    background-color: #333333 !important;
    border: none !important;
  }

  .ant-select-selection-placeholder {
    font-family: helvetica;
    font-size: 16px;
    position: relative;
    left: 1.5%;
    top: 20%;
    color: #807c7c;
    letter-spacing: 0.2px;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    font-family: helvetica;
    font-size: 16px;
    left: 2.5%;
    bottom: -10%;
    color: #807c7c;
  }

  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: 6px;
    border-top-color: rgb(20, 20, 20);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: rgb(20, 20, 20);
    -webkit-box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: 6px;
    border-top-color: rgb(20,20,20);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color:  rgb(20, 20, 20);
    -webkit-box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}
.ant-menu-item a::before {
  color: #582E80;
}
.ant-select-multiple .ant-select-selection-item-content {
  color: rgb(255,255,255);
  background-color: #633d8c;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: rgb(255,255,255);
  background-color: #633d8c;

}

.ant-select-multiple .ant-select-selection-item {
    background-color: #633d8c;
    border: 1px solid #160D1F;
    border-radius: 2px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #333333;
}
  /* SCROLLBAR */
  /* *::-webkit-scrollbar { */
    /* width: ${(props) => props.theme.scrollbar.width};
    height: ${(props) => props.theme.scrollbar.height}; */
  /* } */

   /* Track */
  /* *::-webkit-scrollbar-track { */
    /* background: ${(props) => props.theme.scrollbar.track.background}; */
  /* } */

  /* Handle */
  /* *::-webkit-scrollbar-thumb { */
    /* background: ${(props) => props.theme.scrollbar.thumb.background};
    border-radius: ${(props) => props.theme.scrollbar.thumb.borderRadius};
    box-shadow: ${(props) => props.theme.scrollbar.thumb.boxShadow}; */
  /* } */

  /* Handle on hover */
  /* *::-webkit-scrollbar-thumb:hover { */
    /* background: ${(props) => props.theme.scrollbar.hover.background}; */
  /* } */
`;

export default GlobalStyle;
