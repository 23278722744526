import VideoActionTypes from "./video-types";
import UserActionTypes from "Redux/User/user-types";

const INITIAL_STATE = {
  roomId: undefined,
  videoId: undefined,
  videoUrl: undefined,
  streamId: undefined,
  streamWss: undefined,
  withChat: undefined,
  //Messages
  messages: [],
  messagesLoaded: false,
  messageCreated: null, //null = NO_ACTION, false=FAIL, true=SUCCESSFUL
  //Polls
  polls: [],
  pollAnswers: {},
  userSentAnswer: false,
  uuiPollAnswer: null,
  settings: {},
};

const videoReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    //Messages
    case VideoActionTypes.GET_USER_VIDEO_MESSAGES: {
      const newMessages = Array.isArray(payload) ? payload.reverse() : [];
      const messages = state.messages || [];
      return {
        ...state,
        messages: Array.prototype.concat.apply(messages, newMessages),
        messagesLoaded: true,
      };
    }
    case VideoActionTypes.ADD_MESSAGE_TO_LOCAL_LIST: {
      const messages = state.messages || [];
      return {
        ...state,
        messages: Array.prototype.concat.apply(messages, [payload]),
        messagesLoaded: true,
      };
    }
    case VideoActionTypes.ADD_POLL_TO_LOCAL_LIST: {
      const statePoll = state.polls || [];
      const { idKey } = payload;

      let lasPollFilter = [];

      if (statePoll.length !== 0) {
        lasPollFilter = state.polls.filter((poll) => poll.idKey !== idKey);
      }
      return {
        ...state,
        polls: lasPollFilter,
        messagesLoaded: true,
      };
    }
    case VideoActionTypes.ADD_POLL_ANSWER_COUNTER: {
      const { pollId, answer, idKey } = payload;
      const pollAnswers = { ...state.pollAnswers };

      if (state.uuiPollAnswer !== idKey) {
        if (!pollAnswers[pollId]) {
          pollAnswers[pollId] = {
            [answer]: 1,
          };
        } else {
          pollAnswers[pollId][answer]++;
        }
      }
      return {
        ...state,
        pollAnswers,
        uuiPollAnswer: idKey,
      };
    }
    case VideoActionTypes.CREATE_MESSAGE_SUCCESSFUL: {
      const { idKey, saved } = payload;
      const messages = state.messages || [];
      return {
        ...state,
        messages: !saved
          ? messages.filter((message) => message.idKey !== idKey)
          : messages,
      };
    }
    //Video
    case VideoActionTypes.SET_WATCH_PROPERTIES: {
      return {
        ...state,
        roomId: payload.roomId,
        videoId: payload.videoId,
        videoUrl: payload.videoUrl,
        streamId: payload.streamId,
        streamWss: payload.streamWss,
        withChat: payload.withChat,
        settings: payload.settings,
        isAvailableByMembership: payload.isAvailableByMembership,
      };
    }
    case VideoActionTypes.REFRESH_ROOM_WATCH_PROPERTIES: {
      const room = payload;
      const resource = room.resources[0];

      if (!resource && room?.settings.source === "zoomRoomMeet") {
        return {
          ...state,
          roomId: room.id,
          streamId: room.streamId,
          streamWss: room.streamWss,
          settings: room.settings,
        };
      } else {
        const newsPolls = Array.isArray(resource.polls)
          ? resource.polls.filter((polls) => {
              return polls.isOpen === true;
            })
          : [];
        return {
          ...state,
          roomId: room.id,
          streamId: room.streamId,
          streamWss: room.streamWss,
          videoId: resource.id,
          videoUrl: resource.resourceUrl,
          polls: newsPolls,
          withChat: resource.isChatAvailable,
          messages: Array.isArray(resource.messages)
            ? resource.messages.reverse()
            : [],
          messagesLoaded: resource.messages?.length > 0,
          settings: room.settings,
        };
      }
    }
    case VideoActionTypes.REFRESH_VIDEO_WATCH_PROPERTIES: {
      const video = payload;

      const newsPolls = Array.isArray(video.polls)
        ? video.polls.filter((polls) => {
            return polls.isOpen === true;
          })
        : [];

      return {
        ...state,
        roomId: undefined,
        streamId: undefined,
        streamWss: undefined,
        videoId: video.id,
        videoUrl: video.resourceUrl,
        polls: newsPolls,
        withChat: video.isChatAvailable,
        messages: Array.isArray(video.messages) ? video.messages.reverse() : [],
        messagesLoaded: true, //video.messages?.length > 0,
        settings: video.settings,
      };
    }
    case VideoActionTypes.LOAD_NEW_VIDEO_LIVE_CURRENTLY: {
      return {
        ...state,
        messages: [],
        videoId: payload.videoId,
      };
    }
    case VideoActionTypes.RESET_WATCH_VIDEO_PROPERTIES:
      return INITIAL_STATE;
    case VideoActionTypes.CLOSE_POLL_DATABASE:
      return {
        ...state,
        polls: [],
        messagesLoaded: true,
        userSentAnswer: false,
        pollAnswers: {},
        uuiPollAnswer: null,
      };
    case VideoActionTypes.CHECK_USER_ALREADY_VOTE:
      return {
        ...state,
        userSentAnswer: true,
      };

    case UserActionTypes.LOGOUT:
      return {
        roomId: undefined,
        videoId: undefined,
        videoUrl: undefined,
        streamId: undefined,
        streamWss: undefined,
        withChat: undefined,
        //Messages
        messages: [],
        messagesLoaded: false,
        messageCreated: null, //null = NO_ACTION, false=FAIL, true=SUCCESSFUL
        //Polls
        polls: [],
        pollAnswers: {},
        userSentAnswer: false,
        uuiPollAnswer: null,
      };

    default:
      return state;
  }
};

export default videoReducer;
